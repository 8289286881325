import 'react-tooltip/dist/react-tooltip.css'

import React, { useEffect, useState } from 'react';
import { SpatialDiagnosisResults, defaultSpatialDiagnosisResults } from './shared/interfaces'

import Completed from './views/Completed';
import Diagnosing from './views/Diagnosing'
import ErrorPage from './views/Error';
import Genre from './views/Genre';
import Home from './pages/Home';
import LoginAuthWall from './views/SecretKeyWall';
import Skeleton from 'react-loading-skeleton';
import UploadReference from './views/UploadReference';
import { checkSecretKey } from './helper/client';
import config from './config';
import { useMediaQuery } from "react-responsive";

/**
 * Possible states of the application, this is a single page/route app
 */
export enum AppState {
    HOME_PAGE = 'HOME_PAGE',
    UPLOAD_REFERENCE_PAGE = 'UPLOAD_REFERENCE_PAGE',
    UPLOADING_AUDIO = 'UPLOADING_AUDIO',
    DIAGNOSING_AUDIO = 'DIAGNOSING_AUDIO',
    DIAGNOSIS_COMPLETED = 'DIAGNOSIS_COMPLETED',
    PICK_GENRE_PAGE = 'PICK_GENRE',
    ERROR_PAGE = 'ERROR_PAGE'
}


function App() {    
    
    const [appState, setAppState] = useState<AppState>(AppState.HOME_PAGE)
    const [admFile, setAdmFile] = useState<File>();
    const [refFile, setRefFile] = useState<File>();
    const [response, setResponse] = useState<SpatialDiagnosisResults>()
    const [musicalStyle, setMusicalStyle] = useState<string>('')
    const [secretKeyError, setSecretKeyError] = useState<boolean>(true)
    const [isStillLoading, setIsStillLoading] = useState<boolean>(true)

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 800px)' })

    const checkPass = async () => {
        if(config.env !== 'prod') {
            const secretKeyCheck = await checkSecretKey()
            setSecretKeyError(secretKeyCheck.error)
            setIsStillLoading(false)
        }
    }

    useEffect(() => {
        checkPass()
    }, [])

    const renderLoadingScreen = (): JSX.Element => {
        if(isTabletOrMobile){
            return (
                <div style={{backgroundColor: '#12161C', height: '100vh', padding: 50}}>
                    <Skeleton duration={1} baseColor={'#1b2028b3'} highlightColor={'#232931b3'} height={60} width={'60%'} borderRadius={16} style={{ marginBottom: 14}}/> 
                    <Skeleton duration={1} baseColor={'#1b2028b3'} highlightColor={'#232931b3'} height={60} width={'80%'} borderRadius={16} style={{ marginBottom: 14}}/> 
                    <Skeleton duration={1} baseColor={'#1b2028b3'} highlightColor={'#232931b3'} height={60} width={'100%'} borderRadius={16} style={{ marginBottom: 14}}/> 
                </div>
            )
        } else {
            return (
                <div style={{backgroundColor: '#12161C', height: '100vh', padding: 50}}>
                    <Skeleton duration={1} baseColor={'#1b2028b3'} highlightColor={'#232931b3'} height={60} width={200} borderRadius={16} style={{ marginBottom: 14}}/> 
                    <Skeleton duration={1} baseColor={'#1b2028b3'} highlightColor={'#232931b3'} height={60} width={300} borderRadius={16} style={{ marginBottom: 14}}/> 
                    <Skeleton duration={1} baseColor={'#1b2028b3'} highlightColor={'#232931b3'} height={60} width={400} borderRadius={16} style={{ marginBottom: 14}}/> 
                </div>
            )
        }
    }

    /**
     * Renders page corresponding to the current State
     * of the app.
     * @returns  
     */
    function renderCurrentStatePage() {

        if(isStillLoading) {
            return renderLoadingScreen()
        } else {
            if(config.env != 'prod' && secretKeyError) {
                return <LoginAuthWall setSecretKeyError={setSecretKeyError} />
            }

            else if(appState === AppState.HOME_PAGE) {
                return <Home
                    setAppState={setAppState}
                    setAdmFile={setAdmFile}
                    setRefFile={setRefFile}
                />  
            }
        
            else if (appState === AppState.ERROR_PAGE) {
                return <ErrorPage setState={setAppState} />
            
            }

            else if (appState === AppState.PICK_GENRE_PAGE) {
                return <Genre 
                    setAppState={setAppState} 
                    setMusicalStyle={setMusicalStyle}
                /> 
            }

            else if(appState === AppState.DIAGNOSIS_COMPLETED) {
                return response && <Completed 
                    setAppState={setAppState} 
                    resultsData={response} 
                    admFile={admFile}
                    refFile={refFile}
                />

            }

            else if (appState === AppState.UPLOAD_REFERENCE_PAGE) {
                return <UploadReference 
                    setAppState={setAppState} 
                    setRefFile={setRefFile}
                    appState={appState}
                /> 
            }

            else if(appState === AppState.UPLOADING_AUDIO || AppState.DIAGNOSING_AUDIO ) {
                return <Diagnosing 
                    setResponse={setResponse} 
                    admFile={admFile} 
                    refFile={refFile}
                    setAppState={setAppState} 
                    appState={appState} 
                    musicalStyle={musicalStyle}
                />

            } else {
                <></>
            }
        }


        // return <Completed 
        // setAppState={setAppState} 
        // resultsData={defaultSpatialDiagnosisResults} 
        // admFile={admFile}
        // refFile={refFile}
        // />

        
    }

    return (
        <div> { renderCurrentStatePage()} </div>
    )
}

export default App;
