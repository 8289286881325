import '../../shared/styles/typography.css'
import '../../shared/styles/spacing.css'
import '../../shared/styles/colours.css'

import React, { useState } from "react";

import styles from './styles.module.css'

interface ButtonProps {
    title: string
    onClickFunc: any
    customStyle?: any
    disabled?: boolean
}

function GeneralWhite({title, onClickFunc, disabled}: ButtonProps) {

    const [clicked, setClicked] = useState<boolean>(false)

    return (
        <div 
            className={ !disabled ? styles.container : styles.disabledContainer}
            onClick={() => {
                if(!disabled){
                    setClicked(!clicked)
                    onClickFunc()
                }
                 
            }}>
            <p className='medium-text no-margin black medium-weight'>{ title }</p>
        </div>
    )
}

export default GeneralWhite