import Paper from '@mui/material/Paper';
import Plot from 'react-plotly.js';
import React from 'react';
import { SpatialDiagnosisResults } from '../../shared/interfaces';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Tooltip } from "react-tooltip";
import colours from '../../shared/styles/colours';
import styles from './styles.module.css'

interface SimilarityTableProps {
    resultsData: SpatialDiagnosisResults
}

function prepareTooltip():string {
    return "Presents an overall spectral similarity percentage between the binaural rerender and stereo file, along with a breakdown across three frequency bands."
}

function SimilarityTable({resultsData}: SimilarityTableProps) {

    function createData(
        name: string,
        similarity: string,
      ) {
        return { name, similarity };
      }
      
    const rows = [
        createData('Bass Frequency', resultsData.similarity.bass_frequency),
        createData('High Frequency', resultsData.similarity.high_frequency),
        createData('Mid Frequency', resultsData.similarity.mid_frequency),
        createData('Total', resultsData.similarity.total),
    ];

    return (
        <div 
            data-tooltip-id="similarity-tooltip" 
            data-tooltip-content={prepareTooltip()}
            data-tooltip-place="right"
            style={{width: '100%'}}
        >
            <TableContainer component={Paper} style={{ borderRadius: '10px', overflow: 'hidden', width: '98%', marginBottom: 10 }}>
            <Table aria-label="simple table">
                <TableHead>
                <TableRow style={{ background: colours.secondaryLight, color: colours.secondary, fontFamily: 'Inter-Light' }}>
                    <TableCell style={{ fontFamily: 'Inter-Light', color: 'white', borderBottomWidth: 0, borderColor: 'white', fontSize: 16 }}>
                    Frequency Band
                    </TableCell>
                    <TableCell align="right" style={{ color: 'white', fontFamily: 'Inter-Light', borderBottomWidth: 0, borderColor: 'white', fontSize: 16 }}>
                    Similarity
                    </TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                {rows.map((row) => (
                    <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} style={{ background: colours.secondaryLight, borderBottom: 0 }}>
                    <TableCell component="th" scope="row" style={{ color: '#878787', borderBottom: 0, fontFamily: 'Inter-Light', fontSize: 16 }}>
                        {row.name}
                    </TableCell>
                    <TableCell align="right" style={{ color: 'white', borderBottom: 0, fontSize: 16, fontFamily: 'Inter-Light' }}>
                        {row.similarity}
                    </TableCell>
                    </TableRow>
                ))}
                </TableBody>
            </Table>
            </TableContainer>
            <Tooltip id="similarity-tooltip" style={{ padding: 20, width: 200, zIndex: 4, borderRadius: 10, backgroundColor: '#232a33' }} />
        </div>
    )
}

export default SimilarityTable