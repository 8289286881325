import '../../shared/styles/typography.css'
import '../../shared/styles/spacing.css'
import '../../shared/styles/colours.css'

import { useEffect, useState } from 'react'

import { AppState } from '../../App'
import Papa from 'papaparse'
import styles from './styles.module.css'

interface GenreRow {
    UI: string;
    API: string;
  }

interface MixMasterProps {
    setAppState: React.Dispatch<React.SetStateAction<AppState>>
    setMusicalStyle: React.Dispatch<React.SetStateAction<string>>
}

function Genre ({setAppState, setMusicalStyle}: MixMasterProps) {

    const [genres, setGenres] = useState<GenreRow[]>([]);

    useEffect(() => {
        
        async function getData() {
            try {
                const csv = Papa.parse(await fetchCsv());
                const rows: GenreRow[] = csv.data.map((row: any) => {
                    return {
                        UI: row[0],
                        API: row[1],
                    };
                });
                setGenres(rows);
            } catch (error) {
                console.error('Error fetching CSV:', error);
            }
        }
        
        async function fetchCsv() {
            const response = await fetch('genres.csv');
            const reader = response.body?.getReader();
            const result = await reader?.read();
            const decoder = new TextDecoder('utf-8');
            const csv =  decoder.decode(result?.value);
            return csv;
        }

        getData()

    }, [])

    return (

        <div className={styles.singlePage}>
        <div className={styles.mainPageContainer}>
            <div className={styles.stepContainer}>
                <p className='small-text no-margin'>Step 3/4</p>
            </div>
            <div style={{height: 30}} />
            <div style={{maxWidth: 500}}>
                <h1 className='x-large-text bold no-margin centre-align'>Please select the genre of your spatial mix.</h1>
            </div>
            <div style={{height: 30}} />

            <p className='small-text heavy-opacity medium-text-width no-margin centre-align'>Please select the genre of your spatial track.</p>

            <div className={styles.genreContainer}>
                <div className={styles.genreButtonsContainer}>
                    {genres.map(({ UI, API }) => (
                        <div key={API} className={styles.genreButton} onClick={() => {
                            setMusicalStyle(API)
                            setAppState(AppState.UPLOADING_AUDIO)
                        }}>
                            <p className='small-text no-margin bold'>{UI}</p>
                        </div>
                    ))}
                </div>
            </div>
            

            

        </div>
    </div>
    )
}

export default Genre