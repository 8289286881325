import './styles.css'
import '../../shared/styles/typography.css'
import '../../shared/styles/spacing.css'
import '../../shared/styles/colours.css'

import React, { useState } from 'react'

import { AppState } from '../../App'

interface DragAndDropProps {
    setAppStateCallback: () => void
    setUploadedFile: React.Dispatch<React.SetStateAction<File>>
}


function DragAndDrop ({ setAppStateCallback, setUploadedFile }: DragAndDropProps) {

    const MAX_FILE_SIZE_BYTES = 181193932.8 // 172.8 megabytes
    const SUPPORTED_FILE_TYPES = ['audio/wav', 'audio/mpeg', 'audio/x-wav']

    const [isDragging, setIsDragging] = useState<boolean>()

    const inputRef = React.useRef<HTMLInputElement>(null);

    /**
     * Logic to be performed when a user uploads a file via
     * click or drag and drop.
     * @param event 
     */
    const onFileDrop = async (event: any) => { 
        
        event.preventDefault()

        let file:File

        // detect if drag and drop event or select
        if(event.type === "drop") {
            file = event.dataTransfer.files[0];
        } else {
            file = event.target.files[0]
        }
        
        // Check if the file type is WAV or MP3
        if (SUPPORTED_FILE_TYPES.includes(file.type)) {
            // Check if the file size is within the limit
            if (file.size > MAX_FILE_SIZE_BYTES) {
                alert('File size is too large. Please select a file that is less than 5GB.');
                return;
            }
        } else {
            alert('Please drop only WAV or MP3 files.');
            return
        }

        setAppStateCallback()
        setUploadedFile(file)

    }

    const onDragOver = (event: any) => {
        event.preventDefault()
        setIsDragging(true)
    }

    const onDragLeave = (event: any) => {
        event.preventDefault()
        setIsDragging(false)
    }
      
    const onButtonClick = () => {
        inputRef.current?.click()
    };
  
    return (
        <div
            onDrop={onFileDrop}
            onDragOver={onDragOver}
            onDragLeave={onDragLeave}
            onClick={() => {onButtonClick()}}
        >
            <input id="file-input" type="file" onChange={onFileDrop} className='input-form' ref={inputRef} multiple={false} accept=".wav, .mp3"/>
            <div className='text-logo-container pointer'>
                <div className='upload-button'>
                    <img className='image' src='upload-icon.png' alt='upload'/>
                </div>
                <div className='center' style={{marginTop: 10}}>
                    <p className='small-text no-margin'>Click to upload stereo audio</p>
                    <p className='small-text no-margin'><span className='off-grey'>or Drag and Drop</span></p>
                </div>
            </div>
        </div>
    )
}

export default DragAndDrop