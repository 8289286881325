import React, { useEffect, useState } from 'react';

import styles from './styles.module.css';

interface ErrorProps {
  error: string | React.ReactNode | null;
  setError: any;
}

const ErrorNotification = ({ error, setError }: ErrorProps) => {
    
    const [isShown, setIsShown] = useState(false);

    useEffect(() => {
        if (error) {
            setIsShown(true);

            const timer = setTimeout(() => {
                setIsShown(false);
                setError(null);
            }, 8000);

            return () => clearTimeout(timer);
        }
    }, [error, setError]);

    const handleClearError = () => {
        setError(null);
    };

    return (
        <>
            {error && (
                <div
                    className={`${styles['success-notification']} ${
                        isShown ? styles['show'] : ''
                    }`}
                >
                    <span className={styles['success-message']}>
                        {error}
                    </span>
                    <img src='exit-popup.png' className={styles.cancelJob} onClick={handleClearError}/> 
                </div>
            )}
        </>
    );
};

export default ErrorNotification;
