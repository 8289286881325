import '../../shared/styles/typography.css'
import '../../shared/styles/spacing.css'
import '../../shared/styles/colours.css'

import { SignedUrlResponse, SpatialDiagnosisResults } from '../../shared/interfaces'
import { getSignedUrl, spatialDiagnosis, uploadFile } from '../../helper/client'
import { useEffect, useState } from 'react'

import { AppState } from '../../App'
import DragAndDrop from '../../components/DragAndDrop'
import DragAndDropAlt from '../../components/DragAndDropAlt'
import Footer from '../../components/Footer'
import GeneralWhite from '../../components/GeneralWhite'
import SocialsList from '../../components/SocialsList'
import styles from './styles.module.css'

interface UploadReferenceProps {
    appState: AppState
    setAppState: React.Dispatch<React.SetStateAction<AppState>>
    setRefFile: any
}

function UploadReference ({
    setAppState, 
    setRefFile,
    appState, 
    }:UploadReferenceProps) {

    
    useEffect(() => {
        console.log()

    }, [])

    const handleBackClick = () => {
        setAppState(AppState.HOME_PAGE);
    }

    return (
        <div className={styles.singlePage}>
            <div className={styles.mainPageContainer}>
                <div className={styles.stepContainer}>
                    <p className='small-text no-margin'>Step 2/4</p>
                </div>
                <div style={{height: 30}} />
                <div style={{maxWidth: 500}}>
                    <h1 className='x-large-text bold no-margin centre-align'>Would you like to upload a stereo reference track?</h1>
                </div>
                <div style={{height: 30}} />

                <p className='small-text heavy-opacity medium-text-width no-margin centre-align'>Upload a stereo version of your track so we can calculate how closely your spatial mix matches the spectral characteristics of the stereo version.</p>
                <div style={{height: 60}} />
                <DragAndDropAlt setAppStateCallback={() => { setAppState(AppState.PICK_GENRE_PAGE)}} setUploadedFile={setRefFile}/>
                <div style={{height: 60}} />
                <GeneralWhite title='Skip' onClickFunc={() => {setAppState(AppState.PICK_GENRE_PAGE)}}/>
            </div>
        </div>

    )
}

export default UploadReference