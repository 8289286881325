import React, { useState } from "react";

import styles from './styles.module.css'

interface ButtonProps {
    title: string
    onClick: () => void
    disabled?: boolean
}

function WideButton({ title, onClick, disabled }: ButtonProps) {
    const [clicked, setClicked] = useState<boolean>(false)

    return (
        <div
            className={styles.container}
        
            onClick={() => {
                if (!disabled) {
                    setClicked(!clicked)
                    onClick()
                }
            }}

            
        >
            <p className='medium-text no-margin black medium-weight'>{title}</p>
        </div>
    )
}

export default WideButton
