import '../../shared/styles/typography.css'
import '../../shared/styles/spacing.css'
import '../../shared/styles/colours.css'

import { AppState } from '../../App'
import GeneralWhite from '../../components/GeneralWhite'
import styles from './styles.module.css'

interface ErrorProps {
    setState: any
}

function Error({setState}: ErrorProps) {
    return (
        <div className={styles.pageContainer}>
            <div className={styles.mainContent}>
                <img src='/sad_face.webp' className={styles.coinImage}/> 
                <div style={{height: 20}} />
                <p className='large-text center'>{`Oh no, we have encountered an error.`}</p>
                <div style={{height: 20}} />
                <GeneralWhite title='Go Back' onClickFunc={() => {setState(AppState.HOME_PAGE)}} />
            </div>
        </div>
    )
}

export default Error