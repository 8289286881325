import { ApiResponse, Feedback, SignedUrlResponse } from '../shared/interfaces';
import axios, { AxiosProgressEvent } from 'axios'

import config from '../config';
import configuration from '../config';

const { apiKey, apiUrl } = configuration

enum ReqMethod {
    POST = 'POST',
    GET = 'GET',
    PUT = 'PUT'
}

/**
 * Worker function to handle http requests
 * 
 * @param method 
 * @param url 
 * @param body 
 * @param headers 
 * @returns 
 */

async function makeRequest (
    method: ReqMethod,
    url: string,
    body: unknown,
    headers?: Record<string, string>
): Promise<ApiResponse> {

    const reqHeaders = {
        'x-spatial-api-key': apiKey,
        ...headers
    }

    let res: any

    try {
        res =  await axios.request({
            method, url, data: body, headers: reqHeaders, withCredentials: true

        });
    } catch (e: unknown) {
        console.log(e)
    }

    if(config.debugMode) console.log(res.data)

    return res.data
}

/**
 * Gets a signed url that can be used to upload a file
 * to our bucket.
 * @param body 
 * @returns 
 */

export async function getSignedUrl(body: {fileName: string, audioType: string}): Promise<SignedUrlResponse> {
    const url = `${apiUrl}/api/signed_url`;
    const { error, payload } = await makeRequest(ReqMethod.POST, url, body)
    return payload as SignedUrlResponse
}

/**
 * Executes the spatial diagnosis of a track
 * @param body 
 * @returns ApiResponse
 */
export async function spatialDiagnosis(
    body: { 
        publicUrl: string,
        musicalStyle: string,
        referenceAudioFileLocation?: string
    }): Promise<ApiResponse> {

    const url = `${apiUrl}/api/spatial_diagnosis`;

    let preparedBody;

    if(body.referenceAudioFileLocation) {
        preparedBody = {
            spatialDiagnosisData: {
                audioFileLocation: body.publicUrl,
                referenceAudioFileLocation: body.referenceAudioFileLocation,
                musicalStyle: body.musicalStyle
            }
        }
    } else {
        preparedBody = {
            spatialDiagnosisData: {
                audioFileLocation: body.publicUrl,
                musicalStyle: body.musicalStyle
            }
        }
    }

    const res = await makeRequest(ReqMethod.POST, url, preparedBody);
        
    return res
}

/**
 * Uploads track to our bucket
 * @param signedUrl 
 * @param body 
 */
export async function uploadFile(
    signedUrl: string, 
    body: {file: File},
    onProgress?: (progressEvent: AxiosProgressEvent) => void
    ) {
    await axios.put(signedUrl, body.file, {
        headers: {
            "Content-Type": body.file.type,
        },
        onUploadProgress: onProgress,

    });
}

/**
 * Sends feedback to our db collection
 * @param feedback 
 */
export async function sendFeedback(feedback: Feedback){

    const url = `${apiUrl}/api/feedback`;
    const { error } = await makeRequest(ReqMethod.POST, url, feedback);

    if (error) {
        console.log("Encountered Error")
        throw new Error;
    }
}

/**
 * Adds email to our mailing list
 * @param email
 */
export async function signUpToMailingList(email_address: string){

    const url = `${apiUrl}/api/mailing_list`;
    if(!checkMailFormat(email_address)) return false
    const { error } = await makeRequest(ReqMethod.POST, url, {email_address});

    if (error) {
        console.log("Encountered Error")
        throw new Error;
    }
}

/**
 * Checks if the email provided is in the correct format
 * @param email 
 * @returns 
 */
function checkMailFormat(email: string) {
    //eslint-disable-next-line
    const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if(email.match(mailformat)) return true;
    return false;
}

export async function loginSecretKey(secret_key: string): Promise<ApiResponse>{
    
    const url = `${apiUrl}/api/secret_key`;
    
    const res = await makeRequest(ReqMethod.POST, url, {secret_key});
    
    return res
}

export async function checkSecretKey(): Promise<ApiResponse>{
    
    const url = `${apiUrl}/api/secret_key`;
    
    const res = await makeRequest(ReqMethod.GET, url, {});
    
    return res
}

