import '../../shared/styles/typography.css'
import '../../shared/styles/spacing.css'
import '../../shared/styles/colours.css'

import App, { AppState } from '../../App'
import { GCuploadJob, SignedUrlResponse, SpatialDiagnosisResults } from '../../shared/interfaces'
import { getSignedUrl, spatialDiagnosis, uploadFile } from '../../helper/client'
import { useEffect, useState } from 'react'

import { AxiosProgressEvent } from 'axios'
import ScaleLoader from 'react-spinners/ScaleLoader'
import SocialsList from '../../components/SocialsList'
import TipsCarousel from '../../components/TipsCarousel'
import colours from '../../shared/styles/colours'
import { handleTrackUploadProgress } from '../../helper/utils'
import styles from './styles.module.css'

interface DiagnosingProps {
    appState: AppState
    setAppState: React.Dispatch<React.SetStateAction<AppState>>
    setResponse: any
    admFile: any
    refFile: any
    musicalStyle: string
}

function Diagnosing ({
    setAppState, 
    setResponse,
    admFile, 
    refFile,
    appState, 
    musicalStyle }:DiagnosingProps) {

    const hasRefFile:boolean = refFile instanceof File

    const [admSignedUrlResponse, setAdmSignedUrlResponse] = useState<SignedUrlResponse>()
    const [refSignedUrlResponse, setRefSignedUrlResponse] = useState<SignedUrlResponse>()
    
    const [admUploadJob, setAdmUploadJob] = useState<GCuploadJob>({
        item_name: 'admFile',
        upload_progress: 0,
        status: "UPLOADING"
    })
    
    const [refUploadJob, setRefUploadJob] = useState<GCuploadJob>({
        item_name: 'refFile',
        upload_progress: 0,
        status: "UPLOADING"
    })

    const init = async () => {
        startDiagnosis()
    }

    const uploadAdmFile = async (): Promise<SignedUrlResponse | null> => {
        try {
            const res = await getSignedUrl({ fileName: admFile.name, audioType: admFile.type });
            await uploadFile(res.signedUrl, { file: admFile }, (progressEvent: AxiosProgressEvent) => handleTrackUploadProgress(progressEvent, admFile.name, setAdmUploadJob)
            );
            return res;
        } catch (error) {
            console.error(error);
            return null;
        }
    };
    
    const uploadRefFile = async (): Promise<SignedUrlResponse | null> => {
        try {
            const res = await getSignedUrl({ fileName: refFile.name, audioType: refFile.type });
            await uploadFile(res.signedUrl, { file: refFile }, (progressEvent: AxiosProgressEvent) => handleTrackUploadProgress(progressEvent, refFile.name, setRefUploadJob)
            );
            return res;
        } catch (error) {
            console.error(error);
            return null;
        }
    };
    





    const startDiagnosis = async () => {

        let refRes;

        // Prepare your upload promises based on conditions
        const uploadPromises = [uploadAdmFile()];
        if (hasRefFile) {
            uploadPromises.push(uploadRefFile());
        }

        // Use Promise.all to wait for all upload tasks to complete
        const results = await Promise.all(uploadPromises);

        // Get the URL of the uploaded file
        const admUrl = `https://storage.googleapis.com/${results[0]?.bucketName}/${results[0]?.fileName}`;
        const refUrl = `https://storage.googleapis.com/${results[1]?.bucketName}/${results[1]?.fileName}`;

        setAppState(AppState.DIAGNOSING_AUDIO)

        if(hasRefFile) {
            const { error, payload } = await spatialDiagnosis({ publicUrl: admUrl, musicalStyle: musicalStyle, referenceAudioFileLocation: refUrl })
            setResponse(payload)
            if(error) setAppState(AppState.ERROR_PAGE)
            else setAppState(AppState.DIAGNOSIS_COMPLETED)

        } else {
            const { error, payload } = await spatialDiagnosis({ publicUrl: admUrl, musicalStyle: musicalStyle })
            setResponse(payload)
            if(error) setAppState(AppState.ERROR_PAGE)
            else setAppState(AppState.DIAGNOSIS_COMPLETED)            
        }
    }
    
    useEffect(() => {
        init()
    }, [])

    return (
        <div className={styles.pageContainer}>
            <div className={styles.mainContent}>

            { appState === AppState.UPLOADING_AUDIO ?
                    <>               
                        <p className='large-text no-margin small-margin-bottom'>Uploading Audio</p> 
                        <p className='small-text center off-grey'>This may take several minutes depending on the size of your audio.</p>
                        <div style={{backgroundColor: colours.secondary, padding: 28, borderRadius: 100, marginTop: 20}}>
                            <p className='large-text no-margin'>{ hasRefFile ? ((admUploadJob.upload_progress + refUploadJob.upload_progress) / 2).toFixed(0) : admUploadJob.upload_progress}%</p>
                        </div>
                        <div style={{height: 20}} />
                        <br />
                        <SocialsList />
                    </> : null
            }
                
            { appState === AppState.DIAGNOSING_AUDIO ?
                <>               
                    <img className={styles.loadImage} src='ball.gif' alt="loading..." />
                    <p className='large-text no-margin small-margin-bottom'>Diagnosing Audio</p> 
                    <p className='small-text center off-grey'>This may take several minutes depending on the size of your audio.</p>
                    <br />
                    <SocialsList />
                </> : null
            }
            </div>
        </div>
    )
}

export default Diagnosing