import { AxiosProgressEvent } from "axios";
import { GCuploadJob } from "../shared/interfaces";

export const handleTrackUploadProgress = (
    progressEvent: AxiosProgressEvent,
    fileName: string,
    setUploadJob: any,
) => {
    const { loaded, total } = progressEvent;
  
    if (total !== undefined) {
        const percentage = Math.round((loaded / total) * 100);
  
        const updatedUploadJob: GCuploadJob = {
            item_name: fileName,
            upload_progress: percentage,
            status: "UPLOADING",
        };
  
        setUploadJob(updatedUploadJob)
  
    } else {
        console.log("Total size not available. Unable to calculate progress.");
    }
};