import React from "react"
import { Tooltip } from "react-tooltip";
import styles from './styles.module.css'

interface ResultProps {
    name: string;
    value: string | boolean | number
}

// capitalises the first letter of every word
const toTitleCase = (phrase: string) => {
    return phrase
        .toLowerCase()
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
};
  
/**
 * Converts value into a string and doctors
 * values where necessary. 
 * @param value 
 * @param name 
 * @returns 
 */
function prepareValue(value: string | boolean | number, name: string):string {

    // if bit depth === 0 (is mp3)
    if(name == "Bit Depth") {
        if(!value) return '-' 
    }

    // append '+' if positive
    if(name == "True Peak Loudness") {
        if(value > 0) {
            return ("+" + value).toString().replaceAll('_', ' ')
        }
    }

    // doctor clipping value
    if(name == 'Clipping') {
        if(value === 'MINOR') return 'Yes (Minor)'
        else if (value === 'MAJOR') return 'Yes'
        else return 'None'
    }

    // if(name == "Downmix Control") {
    //     if(value == 'normal') return 'Direct Render'
    //     else return 'Not Direct Render'
    // }

    // if boolean (phase issues, mono-compat)
    if(!value) return 'No'
    else if (value === true) {
        return 'Yes'
    }
    

    const removedUnderscores = value.toString().replaceAll('_', ' ')
    return toTitleCase(removedUnderscores)
}


/**
 * Creates a tootlip for the correct Result
 * @param name 
 * @returns 
 */
function prepareTooltip(name: string) {
    let text = ""

    switch(name) { 
    case "Genre": { 
        text+="The musical style of the audio track you have submitted."
        break; 
    } 
    case "Bit Depth": { 
        text+="The number of bits used to represent each audio sample, affecting the dynamic range and precision of digital audio. Higher bit depth allows for more accurate sound reproduction. Your bit depth should be at least 24 bit to submit your spatial audio to DSPs."
        break; 
    } 
    case "Clipping": { 
        text+="Distortion caused by an audio signal exceeding the system’s limit, resulting in a harsh or distorted sound. Prevent by managing volume and using audio tools."
        break; 
    } 
    case "Loudness": { 
        text+="A measurement of perceived audio loudness in Loudness Units Full Scale (LUFS), accounting for human hearing sensitivity. Used to maintain consistent volume levels across different audio sources."
        break; 
    } 
    case "True Peak Loudness": { 
        text+="True Peak Loudness measures the highest amplitude in an audio waveform, considering intersample peaks that regular peak measurements might miss. Expressed in dBTP or dBFS, it helps prevent clipping and distortion, ensuring optimal audio quality and compatibility with different playback systems."
        break; 
    } 
    case "Downmix Control": {
        text+="Specifies how audio originally designed for multi-channel sound systems will be adapted for playback on simpler audio setups."
        break;
    }
    case "Sample Rate": { 
        text+="The number of audio samples captured per second in digital audio, affecting the frequency range and audio quality. Higher sample rates result in more accurate sound reproduction. This should be at least 48000 Hz to submit your spatial audio to DSPs."
        break; 
    } 
    case "Integrated Loudness": { 
        text+="A measure of the average perceived loudness of an audio signal over its entire duration. It gives a single value that represents the overall loudness level of the audio content, and is used to ensure consistent loudness across different programs and content."
        break; 
    } 
    }
    return text
}

/**
 * Determines if a Result should show a warning
 * depending on the value.
 * @param name 
 * @param value 
 * @returns 
 */
function needsWarning(name: string, value: string | number | boolean): boolean {

    switch(name) { 

    case "Bit Depth": { 
        if(value === 0) return false
        if(value < 24) return true
        break; 
    } 
    case "Clipping": { 
        if(value === "MINOR" || value === "MAJOR") return true
        break; 
    }
    case "Sample Rate": { 
        if(value < 48000) return true
        break; 
    } 
    case "Downmix Control": {
        if(value == 'normal') return false
        else return true
    }
    }

    return false

}

function ResultItem ({ name, value }: ResultProps) {

    return (
        <>
            <div className={styles.itemContainer}
                data-tooltip-id="item-tooltip" 
                data-tooltip-content={prepareTooltip(name)}
                data-tooltip-place="top"
            >
                { needsWarning(name, value) ? <div className={styles.warning}>
                    <img src='warn.png' className={styles.warnImage}/>
                </div> : null }
                <div className={styles.dataItemContainer}>
                    <p className='large-text bold no-margin'>{ prepareValue(value, name) }</p>
                </div>
                <div className={styles.nameContainer}>
                    <p className='small-text no-margin centre-align off-grey'>{ name }</p>
                </div>
            </div>
            <Tooltip style={{padding: 20, width: 200, zIndex: 4, borderRadius: 10, backgroundColor: '#232a33'}} id="item-tooltip" />
        </>
    )
}

export default ResultItem