import React, { useState } from "react";

import ErrorNotification from "../../components/ErrorNotification";
import TextInput from "../../components/TextInput";
import WideButton from "../../components/WideButton";
import { loginSecretKey } from "../../helper/client";
import styles from './styles.module.css'

interface LoginWithAuthWallProps {
    setSecretKeyError: any
}

function LoginAuthWall({setSecretKeyError}: LoginWithAuthWallProps) {

    const [secretKey, setSecretKey] = useState<string>('')
    const [error, setError] = useState<string>()

    return (
        <div className={styles.pageContainer}>
            <div className={styles.mainContentContainer}>
                <ErrorNotification error={error} setError={setError} />
                <div className={styles.signUpContainer}>
                    <p className='x-large-text no-margin light'>Who goes there? 🤔</p>
                    <p className='br-x-x-small' />
                    <p className='small-text no-margin grey-opacity'>The things in here are classified, please sign in to gain access.</p>
                    <p className='br-x-x-small' />
                    <TextInput title="Secret Key"  onChangeSetter={setSecretKey} type='password'/>
                    <p className='br-x-x-small' />
                    <WideButton title='Log In' onClick={ async () => {
                        const { error, message } = await loginSecretKey(secretKey)
                        if(!error){
                            setSecretKeyError(false)
                        } else {
                            setError(message)
                        }
                    }}/>
                </div>
            </div>
        </div>
    )

}

export default LoginAuthWall