import '../../shared/styles/typography.css'
import '../../shared/styles/spacing.css'
import '../../shared/styles/colours.css'

import AboutSection from '../../components/AboutSection'
import { AppState } from '../../App'
import DragAndDrop from '../../components/DragAndDrop'
import Footer from '../../components/Footer'
import SocialsList from '../../components/SocialsList'
import styles from './styles.module.css'

interface DefaultPageProps {
    setAppState: React.Dispatch<React.SetStateAction<AppState>>
    setAdmFile: React.Dispatch<React.SetStateAction<any>>
    setRefFile: React.Dispatch<React.SetStateAction<any>>
}

function Home ({setAppState, setAdmFile, setRefFile}: DefaultPageProps) {
    return (
        <>
            <div className={styles.singlePage}>
                <div className={styles.mainPageContainer}>
                    <h1 className='large-text parabole medium-bold'><span className='large-text parabole medium-bold roex-blue'>spatial check</span> studio</h1>
                    <p className='small-text medium-opacity medium-text-width'>Spatial Check Studio utilises cutting-edge AI technology to analyse your spatial audio, delivering accurate and actionable feedback to help you identify and fix issues in your audio.</p>
                    <DragAndDrop setAppStateCallback={() => { setAppState(AppState.UPLOAD_REFERENCE_PAGE)}} setUploadedFile={setAdmFile}/>
                    <div className={styles.lowerContainer}>
                    <a href='https://www.roexaudio.com/' target='_blank' rel='noopener noreferrer' className={styles.poweredBy}>
                        <div>
                            <p className='small-text no-margin no-underline'>Powered</p>
                            <p className='small-text no-margin'>by <span className='medium-text medium-weight parabole no-underline roex-blue'>roex</span></p>
                        </div>
                    </a>         
                    <SocialsList />
                    </div>
                </div>
            </div>
            {/* <div className={styles.singlePage}>
                <AboutSection />
            </div> */}
        </>
    )
}

export default Home