import React from "react";
import styles from './styles.module.css'

interface TextInputProps {
    title: string
    type: string
    onChangeSetter: any
    isSecret?: boolean
    onKeyPress?: any
    value?: string
    isEditable?: boolean
    instruction?: string
}

function TextInput({ title, type, onChangeSetter, isSecret, onKeyPress, value, instruction, isEditable = true }: TextInputProps) {
    const inputType = isSecret ? 'password' : type;

    return (
        <div className={styles.mainContainer}>
            <p className='small-text medium-weight no-margin'>{title}</p>
            <input
                type={inputType}
                defaultValue={value ?? ''}
                name="name"
                className={styles.inputBox}
                onKeyDown={onKeyPress}
                onChange={(e) => onChangeSetter(e.target.value)}
                readOnly={!isEditable}
            />
            <p className='small-text medium-weight no-margin grey-opacity x-small-margin-top'>{instruction}</p>

        </div>
    )
}

export default TextInput
