import '../../shared/styles/typography.css'
import '../../shared/styles/spacing.css'
import '../../shared/styles/colours.css'
import 'react-tooltip/dist/react-tooltip.css'

import { useEffect, useRef, useState } from 'react'

import { AppState } from '../../App'
import MediaPlayer from '../../components/MediaPlayer'
import Paper from '@mui/material/Paper';
import { Rating } from 'react-simple-star-rating'
import ResultItem from '../../components/ResultItem'
import SimilarityTable from '../../components/SimilarityTable'
import { SpatialDiagnosisResults } from '../../shared/interfaces'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TonalGraph from '../../components/TonalGraph'
import TrimTable from '../../components/TrimTable'
import { TypeAnimation } from 'react-type-animation';
import colours from '../../shared/styles/colours'
import { sendFeedback } from '../../helper/client'
import styles from './styles.module.css'

interface CompletedPageProps {
    setAppState: React.Dispatch<React.SetStateAction<AppState>>
    resultsData: SpatialDiagnosisResults
    admFile: any
    refFile?: any
}

function Completed ({ resultsData, setAppState, admFile, refFile }: CompletedPageProps) {

    const [givingFeedback, setGivingFeedback] = useState<boolean>(false)
    const [rating, setRating] = useState(0)
    const [comment, setComment] = useState('');
    const [senderEmail, setSenderEmail] = useState('');

    interface childRefInterface {
        handleStop: () => void;
    }

    const handleBackClick = () => {
        setAppState(AppState.HOME_PAGE);
        if (childRef.current) {
            childRef.current.handleStop();
        }
    }

    function createData(
        name: string,
        similarity: string,
      ) {
        return { name, similarity };
      }
      
    const rows = [
        createData('Bass Frequency', resultsData.similarity.bass_frequency),
        createData('High Frequency', resultsData.similarity.high_frequency),
        createData('Mid Frequency', resultsData.similarity.mid_frequency),
        createData('Total', resultsData.similarity.total),
    ];

    // Catch Rating value
    const handleRating = (rate: number) => {
        setRating(rate)
    }

    // Handle feedback comment change.
    const handleCommentChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setComment(event.target.value);
    };

    // Handle feedback comment change.
    const handleEmailChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setSenderEmail(event.target.value);
    };


    const givenFeedback = ():boolean => {
        return comment !== "" && senderEmail != "" && rating !== 0
    }

    const childRef = useRef<childRefInterface>(null)

    return (
        <div className={styles.compPageContainer}> 
            <div className={styles.headerContainer}>
                <button className={styles.backButtonContainer}>
                    <p className='small-text no-margin button-text'>
                        <img 
                            src='back-arrow.png' 
                            className={styles.backArrow} 
                            alt='back'
                            onClick={() => { handleBackClick() }}
                        />
                    </p>
                </button>
                <div className={styles.nameContainer}>
                    <h1 className='large-text parabole no-margin center'><span className='roex-blue'>spatial check</span> studio</h1>
                </div>
                <div className={styles.rightFiller}>
                    <a href='https://www.roexaudio.com/' target='_blank' rel='noopener noreferrer' className={styles.poweredBy}>
                            <div>
                                <p className='small-text no-margin no-underline'>Powered</p>
                                <p className='small-text no-margin'>by <span className='medium-text medium-weight parabole no-underline roex-blue'>roex</span></p>
                            </div>
                    </a>                   
                </div>
            </div>

            <div style={{width: '100%'}}>
            <div className={styles.metaHeader}>
                <div className={styles.innerMetaCont}>
                    <div className={styles.left}>
                        <p className='medium-text no-margin'>
                                Spatial Audio
                        </p>
                    </div>
                    <div className={styles.right}>
                        <p className='medium-text no-margin no-wrap'>
                               {admFile ? admFile.name : 'lorem.wav'}
                        </p>
                    </div> 
                </div>
                {
                    resultsData.similarity.bass_frequency ? 
                    <>
                        <div style={{width: 100, height: 14}}/>
                            <div className={styles.innerMetaCont}>
                            <div className={styles.left}>
                                <p className='medium-text no-margin'>
                                        Stereo Audio
                                </p>
                            </div>
                            <div className={styles.right}>
                                <p className='medium-text no-margin no-wrap'>
                                    {refFile ? refFile.name : 'epsum.wav'}
                                </p>
                            </div> 
                        </div>
                    </>
                     : null

                }

            </div>
            </div>


            {/* <MediaPlayer file={admFile} ref={childRef}/> */}
            
            <div className={styles.middleContainer}>
                <div className={styles.dataContainer}>
                    <ResultItem name='Genre' value={resultsData?.musical_style.toUpperCase()}/>
                    <ResultItem name="Sample Rate" value={resultsData?.sample_rate}/>
                    <ResultItem name='Bit Depth' value={resultsData?.bit_depth}/> 
                    <ResultItem name='Clipping' value={resultsData?.true_peak.clipping}/>
                    <ResultItem name='Integrated Loudness' value={resultsData?.loudness.spatial_loudness_lufs.toFixed(1)}/>
                    <ResultItem name='True Peak Loudness' value={resultsData?.true_peak.true_peak_loudness_dbfs.toFixed(1)}/>
                    <ResultItem name='Downmix Control' value={resultsData?.downmix_setting.warp_mode}/>

                    {
                        resultsData.similarity?.bass_frequency ? <SimilarityTable resultsData={resultsData} /> : null
                    }
                    
                    <div className={styles.tonalContainer}>
                        <TonalGraph resultsData={resultsData}/>
                    </div>

                    <TrimTable resultsData={resultsData} />


                </div>  
                <div className={styles.textPanel}>
                    <p className='small-text no-margin'>
                        <TypeAnimation
                            sequence={[
                                resultsData.summary
                            ]}
                            wrapper="span"
                            cursor={true}
                            speed={98}
                        />
                    </p>
                </div>    
            </div>  

            <div 
                className={ givingFeedback ? styles.feedbackContainer : styles.feedbackButton} 
                onClick={ () => {!givingFeedback ? setGivingFeedback(true) : null }}
            >
                {
                    givingFeedback ?
                        <div>
                            <div className={styles.closeButton} onClick={() => {setGivingFeedback(false)}}>
                                <p className='no-margin no-padding bold'>-</p>
                            </div>
                            <textarea name="email" className={styles.feedbackEmailContainer} maxLength={600} onChange={handleEmailChange} placeholder={'Your Email..'}/>
                            <textarea name="name" className={styles.feedbackCommentsContainer} maxLength={600} onChange={handleCommentChange} placeholder={'Feedback...'}/>
                            <div className={styles.submitContainer}>
                                <Rating
                                    fillColor='#FFD700'
                                    size={30}
                                    onClick={handleRating}
                                />
                                <button className={givenFeedback() ? styles.submitButton : styles.submitButtonDisabled} 
                                    disabled={!givenFeedback()}
                                    onClick={ () => {
                                        setGivingFeedback(false)
                                        setRating(0)
                                        sendFeedback({comment: comment, rating: rating, sender_email: senderEmail})
                                        setComment('')
                                    }}>
                                    <p className='small-text black bold' >Submit</p>
                                </button>
                            </div>
                        </div>
                        :
                        <p className='small-text no-margin' id="feedback-button">Give Feedback</p>
                }
            </div>
        </div> 
    )
}

export default Completed