import '../../shared/styles/typography.css'
import '../../shared/styles/spacing.css'
import '../../shared/styles/colours.css'

import React, { useState } from "react";

import styles from './styles.module.css'

interface ButtonProps {
    title: string
    onClickFunc: any
    noMargin?: boolean
    stretch?: boolean
    disabled?: boolean
}

function GeneralGrey({title, onClickFunc, noMargin, stretch, disabled}: ButtonProps) {

    const [clicked, setClicked] = useState<boolean>(false)

    return (
        <div 
            className={`${stretch ? styles.wideContainer : styles.container} ${
                disabled ? styles.containerDisabled : "" 
            } ${
                noMargin ? styles.noMargin : ""
            }`}
            onClick={() => {
                if(disabled) return
                
                setClicked(!clicked)
                onClickFunc()
            }}>
            <p className='medium-text no-margin white medium-weight'>{ title }</p>
        </div>
    )
}

export default GeneralGrey