import React, { useState } from 'react';

import GeneralGrey from '../GeneralGrey';
import Paper from '@mui/material/Paper';
import Plot from 'react-plotly.js';
import { SpatialDiagnosisResults } from '../../shared/interfaces';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Tooltip } from "react-tooltip";
import colours from '../../shared/styles/colours';
import styles from './styles.module.css'

interface TrimableProps {
    resultsData: SpatialDiagnosisResults
}

function prepareTooltip():string {
    return "Trim controls determine the signal level sent to surround and height speakers across various downmix configurations."
}

function TrimTable({resultsData}: TrimableProps) {

    const [isExpanded, setIsExpanded] = useState<boolean>(false)

    // Assuming createData is a function that takes two parameters: key and value.
    function createData(key: string, value: string) {
        return { key, value };
    }

    // Dynamically generate rows from trim_settings
    const rows = Object.entries(resultsData.trim.trim_settings).map(([key, value]) => createData(key, value));
    

    const displayedRows = isExpanded ? rows : rows.slice(0, 2);


    return (
        <>
                <div 
            data-tooltip-id="similarity-tooltip" 
            data-tooltip-content={prepareTooltip()}
            data-tooltip-place="right"
            style={{width: '100%'}}
        >
            <TableContainer component={Paper} style={{ borderRadius: '10px', overflow: 'hidden', width: '98%', marginTop: 10 }}>
            <Table aria-label="simple table">
                <TableHead>
                <TableRow style={{ background: colours.secondaryLight, color: colours.secondary, fontFamily: 'Inter-Light' }}>
                    <TableCell style={{ fontFamily: 'Inter-Light', color: 'white', borderBottomWidth: 0, borderColor: 'white', fontSize: 16 }}>
                    Configuration
                    </TableCell>
                    <TableCell align="right" style={{ color: 'white', fontFamily: 'Inter-Light', borderBottomWidth: 0, borderColor: 'white', fontSize: 16 }}>
                    Setting
                    </TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                { displayedRows.map((row) => (
                    <TableRow key={row.key} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} style={{ background: colours.secondaryLight, borderBottom: 0 }}>
                    <TableCell component="th" scope="row" style={{ color: '#878787', borderBottom: 0, fontFamily: 'Inter-Light', fontSize: 16 }}>
                        {row.key}
                    </TableCell>
                    <TableCell align="right" style={{ color: 'white', borderBottom: 0, fontSize: 16, fontFamily: 'Inter-Light' }}>
                        {row.value}
                    </TableCell>
                    </TableRow>
                ))}
                </TableBody>
            </Table>
            <Tooltip id="similarity-tooltip" style={{ padding: 20, width: 200, zIndex: 4, borderRadius: 10, backgroundColor: '#232a33' }} />

            </TableContainer>

        </div>
        <div style={{display: 'block', width: 100, marginTop: 10}}>
                <GeneralGrey stretch={false} title={isExpanded ? 'Collapse' : 'Expand'} onClickFunc={() => setIsExpanded(!isExpanded)} noMargin={true}/>
            </div>
        </>

    )
}

export default TrimTable